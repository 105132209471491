import React, { useState } from 'react';

const LinkedIn = ({ post, activeClass }) => {
    const [showMore, setShowMore] = useState({});

    const LinkedInPageURL = `https://www.linkedin.com/company/canon-financial-services-inc./posts/`;

    let remainingTextSize = [];

    const parseHashtags = (commentary) => {
        let parsedCommentary = commentary.replace(/\{hashtag\|\\#\|([^}]+)\}/g, "#$1"); // To parse hashtags
        parsedCommentary = parsedCommentary.replace(/\@\[(.*?)\]\(urn:li:person:[^)]+\)/g, "$1"); // To parse names
        let truncatedText = parsedCommentary.substring(0, 150);
        remainingTextSize[post.id] = parsedCommentary;
        let remainingText = showMore[post.id] ? parsedCommentary : truncatedText;
        return remainingText;
    };

    return (
        <div className={"carousel-item" + activeClass} data-bs-interval={100000000}>
            <div className='d-flex align-items-center mb-3'>
                <a href={LinkedInPageURL} target='_blank' className='link-underline link-underline-opacity-0 d-flex gap-3 align-items-center'>
                    <span className='linkedin-dp bg-white d-inline-flex align-items-center justify-content-center shadow-lg'>
                        <img src="images/Canon-Financial-services-logo.svg" alt="Canon Financial Services, Inc." />
                    </span>
                    <span className='d-inline-flex flex-column gap-1'>
                        <h3 className='font-16 color-black font-bold m-0'>Canon Financial Services, Inc.</h3>
                        <span className='font-14 grey-color-v1'>Published at: {new Date(post.publishedAtDate).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</span>
                    </span>
                </a>
            </div>
            <div className='mb-3'>
                <span className='font-16 color-black font-regular' dangerouslySetInnerHTML={{ __html: parseHashtags(post.commentary) }} />
                {
                    remainingTextSize[post.id].length > 150 ? (
                        <>
                            {
                                !showMore[post.id] ? (
                                    <a href="#" className='link-underline link-underline-opacity-0 font-14' onClick={(e) => {
                                        e.preventDefault();
                                        setShowMore((prev) => ({ ...prev, [post.id]: true }));
                                    }}>
                                        ...See More
                                    </a>
                                ) : (
                                    <a href="#" className='link-underline link-underline-opacity-0 font-14' onClick={(e) => {
                                        e.preventDefault();
                                        setShowMore((prev) => ({ ...prev, [post.id]: false }));
                                    }}> See Less
                                    </a>
                                )
                            }
                        </>
                    ) : (
                        ""
                    )
                }
            </div>

            {post.content?.media?.image && (
                <img className='text-center post-img' src={post.content.media.image.downloadUrl} alt="post image" />
            )}
            {post.content?.media?.video && (
                <video className='post-video' controls>
                    <source src={post.content.media.video.downloadUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
};

export default LinkedIn;